.page-login {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .object1 {
    position: absolute;
    top: 0;
    right: 0;
  }

  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @keyframes has-shown {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes has-shown-login-box {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes text-reveal {
    from {
      transform: translateX(-101%);
    }
    to {
      transform: translateX(101%);
    }
  }

  .has-shown-white-points {
    animation: has-shown 2s forwards ease-in;
  }

  .has-shown-blue-points {
    animation: has-shown 1.2s forwards ease-in;
  }

  .has-shown-squad {
    animation: has-shown 0.7s forwards ease-in;
  }

  .has-shown-background {
    animation: has-shown 0.5s forwards ease-in;
  }

  .has-shown-login-box {
    animation: has-shown-login-box 500ms forwards;
    background-size: contain;
  }

  .text-reveal-slow {
    overflow: hidden;
    position: relative;
    &::after {
      animation: text-reveal 2.5s cubic-bezier(0.85, 0.85, 0.85, 0.85) forwards;
      background-color: #98d200;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .text-reveal-fast {
    overflow: hidden;
    position: relative;
    &::after {
      animation: text-reveal 2s cubic-bezier(0.1, 0.1, 0.1, 0.1) forwards;
      background-color: #98d200;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .faded {
    animation: fade 1ms 1s forwards;
    opacity: 0;
    display: none;
  }

  .object2 {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .card-login {
    width: 500px;
    color: #5e676a;
    border-radius: 10px;

    .title {
      font-size: 20pt;
      text-align: center;
    }

    .sub-title {
      font-size: 13pt;
      text-align: center;
    }

    .input-login {
      font-size: 10pt;
      padding: 24px 18px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      &:focus {
        box-shadow: none;
        border-color: rgb(210, 210, 210);
      }
    }

    .input-group-text {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .input-icon {
      color: #7c7f82;
    }

    .input-options {
      font-size: 9pt;
      color: #7c7f82;
      margin: 2px;
    }

    .link-password {
      color: #5264b0;
      font-size: 10pt;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }

    .form-check-input-box {
      padding-bottom: 2px;
    }

    .buttom-login {
      background-color: #5264b0;
      border: #5264b0;
      border-radius: 10px;
      padding: 10px 15px;
    }
    .button-login-shape {
      border-radius: 10px;
      padding: 10px 15px;
    }
    .text-decoration-none {
      text-decoration: none !important;
    }
  }

  .box-content {
    font-weight: 600;
    font-size: 22pt;
    color: #263573;
    .sub-box {
      background-color: #98d200;
      min-width: 400px;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.input-group input {
  border-right: none;
}

.input-group .input-group-addon {
  background: inherit;
}

.input-group-text {
  background-color: #ffffff !important;
}

.background-image {
  background-color: #f1f1f1;
  background-repeat: no-repeat;
  background-image: url("./../img/bg.png");
  background-position: top right;
}
